import types from "@/common/types"
import arrays from "@/common/arrays";

class Auctions {

  valid(auction) {
    const errors = []

    if (!auction) {
      errors.push('لم يتم تحديد بيانات المزاد')
    } else {
      if (!auction.manufacturer)
        errors.push('لم يتم تحديد نوع المركبة')

      if (!auction.model)
        errors.push('لم يتم تحديد موديل المركبة')

      if (!auction.opening_price)
        errors.push('لم يتم تحديد سعر الإفتتاح')

      if (!auction.min_price)
        errors.push('لم يتم تحديد الحد الأدنى للبيع')

      if (!auction.lowest_bid)
        errors.push('لم يتم تحديد الحد الأدنى للمزاودة')

      if (!auction.company_id)
        errors.push('لم يتم تحديد شركة التأمين')

      if (!auction.finish_date)
        errors.push('لم يتم تحديد تاريخ الإنتهاء')

      if (!auction.plate_no)
        errors.push('لم يتم تحديد رقم اللوحة')

      if (types.checkY(auction.transferred) && !auction.city_id)
        errors.push('لم يتم تحديد مكان النقل')

      if (!types.checkY(auction.approved))
        errors.push('لم يتم الموافقة على المزاد')
    }

    return {errors, state: arrays.empty(errors)}
  }

}

const auctions = new Auctions()
export default auctions